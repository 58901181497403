body {
  margin: 0;
  background: #fafafa;
  font-family: '72';
}

@font-face {
  font-family: "72";
  font-style: normal;
  font-weight: 400;
  src: local("72"),
      url(https://ui5.sap.com/sdk/resources/sap/ui/core/themes/sap_fiori_3/fonts/72-Regular.woff2?ui5-webcomponents) format("woff2");
 }

 @font-face {
  font-family: 'SAPicons';
  src: url('./SAP-icons.ttf') format('truetype');    
}

.custom-select {
  background: white !important;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

/* .swal2-icon {
  width: 35px !important;
  height: 35px !important;
  margin-bottom: .5rem !important;
  margin-top: .5rem !important;
} */

/* .swal2-icon-content {
  font-size: 30px !important;
} */

.swal2-title {
  font-size: 1.2rem !important;
}

