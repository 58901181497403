.btn-bgSAP {
  background: #354a5f;
  color: white;
  border-radius: 5px;
  padding: .25rem!important;
}

.text-SAP {
  color: #296096 !important;
}

.SAPicons {
  font-family: 'SAPicons';
  font-size: 1.5rem;
  color: rgb(52, 114, 182)
}

.backModal {
  background-color: rgba(0, 0, 0, 0.3);
}