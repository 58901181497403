.bg-navbar {
    background: #354a5f;
}

.bg-button, .bg-button:focus, .bg-button.focus {
    background: #354a5f;
    box-shadow: #354a5f !important;
    outline: none !important;
    color: white;
}

#BrandBase p {
    line-height: 1 !important;
}