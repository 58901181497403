.overFlowTable {
  max-height: 50vh;
  overflow: auto;
}

@media screen and (min-width: 600px) {
  .overFlowTable table {
    table-layout: fixed;
  }
}

.overFlowTable table {
  font-size: 14px;
}

table tbody tr td {
  padding: 0.3rem !important;
}

.overFlowTable thead tr th {
  position: sticky;
  top: 0;
}

.stickyContent {
  height: 35vh;
}

.stickyContent thead tr th {
  position: sticky;
  top: 0;
}

#docRelTalbe tr th, #docRelTalbe td {
  padding: 0.3rem;
}

/* width */

::-webkit-scrollbar {
  width: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e9ecef;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  background: #e9ecef;
  border-radius: 10px;
}